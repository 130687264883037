import React from "react";

// import layout
import WorkLayout from "../../layout/work-layout";

// components
import { StaticImage } from "gatsby-plugin-image";
import ContactLink from "../../micro-components/contact-link";
import Seo from "../../utils/seo";

const Work = () => {
  // DEFINE THERE ALL THE WORK CONSTs
  const title = "AVAVAV MFW",
    desc = `Ripresa video Milano Fashion Week 2022 & 2023.`,
    subtitle = "+20Mln Views",
    roles = ["Videomaker"],
    videoSrc = [
      "https://www.youtube.com/watch?v=tscFXGP51m4",
      // 'https://www.youtube.com/watch?v=7nHPVCUH7ok',
      // 'https://www.youtube.com/watch?v=wDM5DEFPVd0',
      // 'https://www.youtube.com/watch?v=dUABrbsH3Zg&t=19s',
    ],
    // put this TRUE only if there are logos to show
    logo = true;

  return (
    <WorkLayout
      title={title}
      subtitle={subtitle}
      roles={roles}
      videoSrc={videoSrc}
      logo={logo}
    >
      <Seo title={title} description={desc} />
      {/* COVER IMG */}
      <StaticImage
        src="../../images/cover-avavav-2023.jpg"
        alt="Cover per la sfilata di moda AVAVAV MFW"
        className="work-cover shadow-black"
      />
      {/* DESC */}
      <p className="work-description desc-1 margin-bottom">
        <span>
          <em>22 Settembre 2022</em> | <em>21 Febbraio 2023</em> |{" "}
          <em>24 Settembre 2023</em> - Milano Fashion Week.
        </span>
        <span>
          AVAVAV nasce nel 2017 a Firenze dai partner Adam e Linda Friberg La
          coppia, avendo precedentemente co-fondato marchi come Monki, Weekday e
          Cheap Monday, era desiderosa di creare un marchio che non solo fosse
          sostenibile, ma offrisse capi di lusso senza tempo, sostenibili.
        </span>
        <span>
          AVAVAV sposa la sicurezza e le linee pulite della sensibilità
          scandinava, con il dettaglio e la stravaganza della moda italiana.
        </span>
      </p>
      {/* DESC n.2 */}
      <p className="work-description desc-2 margin-bottom">
        <span>
          I video sono diventati virali sulle piattaforme social di maggiore
          spessore, come Instagram, Tiktok e YouTube.
        </span>
      </p>
      {/* DESC n.3 */}
      <p className="work-description desc-3 margin-bottom">
        <span>
          Progetto in collaborazione con{" "}
          <ContactLink
            to="https://www.leonardocasalini.com/"
            external={true}
            title="Per il sito di Leonardo Casalini"
          >
            {" "}
            Leonardo Casalini
          </ContactLink>
          .
        </span>
      </p>
      {/* photo */}
      <StaticImage
        src="../../images/photo/avavav-pic-2.jpeg"
        alt="Foto AVAVAV 1"
        className="work-img work-img-contain video-1 margin-bottom"
        imgClassName="img shadow-black"
        objectFit="contain"
        quality={100}
      />
      <StaticImage
        src="../../images/photo/avavav-pic.jpeg"
        alt="Foto AVAVAV 2"
        className="work-img video-2 shadow-black"
        quality={100}
      />
      {/* ADD HERE ALL THE LOGOS */}
      <div className="logo-container">
        <StaticImage
          src="../../images/logo/logo-avavav.webp"
          alt="Logo AVAVAV"
          className="work-logo-big logo-1"
        />
        <StaticImage
          src="../../images/logo/logo-mfw.jpeg"
          alt="Logo Milan fashion week"
          className="work-logo-big logo-3"
        />
      </div>
    </WorkLayout>
  );
};

export default Work;
